import dev from "./dev";
import uat from "./uat";
import prod from "./prod";
import oem from "./oem";
import oversea from "./oversea";
export function getHost(bucketName: String) {
	switch (import.meta.env.MODE) {
		case "development":
			return dev.oss[bucketName];
		case "uat":
			return uat.oss[bucketName];
		case "oem":
			return oem.oss[bucketName];
		case "prod":
			return prod.oss[bucketName];
		case "oversea":
			return oversea.oss[bucketName];
		default:
			return prod.oss[bucketName];
	}
}

export function getLangHost() {
	return getHost("shop");
}